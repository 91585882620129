@import "@/styles/base/typography";
@import "@/styles/abstract/mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-neutral-01);
  overflow: hidden;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }
}

.tableCell {
  height: var(--space-size-6);
  padding: 0 var(--space-size-1-5);
}

.tableSubtitles {
  height: var(--space-size-3);
  color: var(--color-neutral-10);
  padding: 0 var(--space-size-1-5);
  background-color: var(--color-neutral-03);
  text-transform: uppercase;
  @include body-xs-bold;
}

.borderTop {
  border-top: 0.1rem solid var(--color-neutral-05);
}

.borderRight {
  border-right: 0.1rem solid var(--color-neutral-05);
}

.borderBottom {
  border-bottom: 0.1rem solid var(--color-neutral-05);
}

.borderLeft {
  border-left: 0.1rem solid var(--color-neutral-05);
}
